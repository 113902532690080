@font-face {
  font-family: TiltNeon-Regular;
  src: url(../assets/fonts/TiltNeon-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Poppins-Regular;
  src: url(../assets/fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url(../assets/fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: Poppins-Medium;
  src: url(../assets/fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}

$TiltNeon-Regular: TiltNeon-Regular;
$Poppins-Regular: Poppins-Regular;
$Poppins-SemiBold: Poppins-SemiBold;
$Poppins-Medium: Poppins-Medium;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  --white: #fff;
  --black: #000;
}

img {
  -webkit-tap-highlight-color: transparent;
}

.App {
  width: 100%;
}

.landingPage-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1rem;

  .hero-section {
    background: rgb(255, 79, 59);
    background: linear-gradient(180deg, rgba(255, 79, 59, 1) 0%, rgba(252, 176, 69, 0) 60%);
    //min-height: 100vh;

    .hero-logo {
      position: absolute;
      top: 2rem;
      left: 2rem;
      max-width: 15vw;
    }

    .hero-content {
      justify-content: space-around;
      align-items: center;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .hero-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        justify-self: center;

        .content {
          font-size: 3vw;
          font-family: $Poppins-SemiBold;
          width: 90%;
          justify-self: center;
          align-self: center;
          color: #4b3020;
        }

        .store-logo {
          display: flex;
          width: 100%;
          justify-content: center;
          column-gap: 1rem;
          margin-top: 1rem;

          img {
            max-width: 30%;
          }
        }
      }

      .hero-lottie {
        width: 80%;
        justify-self: center;
      }
    }

    @media screen and (max-width: 600px) {
      min-height: auto;
      row-gap: 1rem;
      .hero-content {
        grid-template-columns: repeat(1, 1fr);

        .hero-left {
          align-items: center;

          .content {
            text-align: center;
            width: 70%;
            font-size: 5vw;
            margin-top: 5rem;
          }

          .store-logo {
            width: 90%;
            justify-content: center;
          }
        }


      }
      .hero-logo {
        max-width: 20vw;
      }
    }
  }

  .screens-section {
    width: 85%;
    display: flex;
    padding: 2rem 3rem;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    border-radius: 1rem;
    background: rgb(159, 153, 255);
    background: linear-gradient(90deg, rgb(165 255 129 / 54%) 0%, rgb(185 238 246 / 57%) 35%, rgb(179 242 255 / 49%) 100%);

    img {
      width: 20%;
    }

    p {
      font-size: 3vw;
      font-family: Poppins-SemiBold;
      width: 65%;
    }

    &.reverse {
      flex-direction: row-reverse;
      background: rgb(255, 79, 59);
      background: radial-gradient(circle, rgba(255, 79, 59, 0.75) 0%, rgba(148, 187, 233, 0.76) 100%);
    }

    @media screen and (max-width: 600px) {
      padding: 1rem;
      flex-direction: column;
      p {
        font-size: 5vw;
        width: 100%;
        text-align: center;
      }
      row-gap: 1rem;
      img {
        width: 80%;
      }
      &.reverse {
        flex-direction: column;
      }
    }
  }

  footer {
    width: 100%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    display: flex;
    justify-content: space-around;
    padding: 1rem 2rem;
    background: rgb(255, 79, 59);
    background: linear-gradient(180deg, rgba(255, 79, 59, 1) 0%, rgba(252, 176, 69, 0) 60%);
    min-height: 20vh;

    .contact-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      p {
        font-size: 1.5vw;
        font-family: $Poppins-Regular;
        color: #4b3020;

      }

      a {
        color: #4b3020;
        text-decoration: none;
        font-size: 1.5vw;
        font-family: $Poppins-Regular;

      }
    }

    .store {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 1rem;

      img {
        max-width: 20%;

      }
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
      row-gap: 1rem;
      .contact-details {
        align-items: center;

        p {
          font-size: 4vw;
        }

        a {
          font-size: 4vw;
        }
      }
      .store
      img {
        max-width: 30%;
      }
    }

  }
}

.tandc {
  width: 90%;
  font-family: Poppins-Regular;
  font-size: .825rem;
  margin: 0 auto;
  color: #4b3020;
  b {
    font-size: .8rem;
    color: #ff4f3b;
    padding-right: 1rem;
  }

  h1 {
    width: 100%;
    margin: 1rem 0 3rem 0;
    text-align: center;
    color: #ff4f3b;

  }

  h5 {
    text-decoration: underline;
    color: #ff4f3b;
    margin: 2rem 0;
    text-align: start;
  }
}
